import React from "react";
import logo from "../images/tipkit-logo.svg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  const navigateToInnovation = () => {
    navigate("/#innovation");
  };
  const navigateToPricing = () => {
    navigate("/pricing");
    window.scrollTo(0, 0);
  };
  const navigateToContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  const handlLogin = () => {
    window.open("https://dashboard.tipkit.co/login", "_blank");
  };

  return (
    <div className="navbar fixed top-0 left-0 right-0 z-50 hover:bg-white hover:shadow-sm bg-transparent backdrop-blur-3xl backdrop-opacity-80 sm:px-20 sm:py-4 p-2 hover:border-b-2">
      <div className="navbar-start">
        {/* Toggle Icon */}
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
          >
            <li onClick={navigateToInnovation}>
              <a className="hover:bg-white hover:text-success">Innovation</a>
            </li>
            <li onClick={navigateToPricing}>
              <a className="hover:bg-white hover:text-success">Pricing</a>
            </li>
            <li onClick={navigateToContact}>
              <a className="hover:bg-white hover:text-success">Unlock</a>
            </li>
          </ul>
        </div>
        <a className="sm:block hidden" onClick={navigateToHome}>
          <img src={logo} width={100} alt="logo" />
        </a>
      </div>
      <div className="navbar-center hidden lg:block">
        <ul className="menu menu-horizontal px-1 text-primary text-lg">
          <li onClick={navigateToInnovation}>
            <a className="hover:bg-white hover:text-success">Innovation</a>
          </li>
          <li onClick={navigateToPricing}>
            <a className="hover:bg-white hover:text-success">Pricing</a>
          </li>
          <li onClick={navigateToContact}>
            <a className="hover:bg-white hover:text-success">Unlock</a>
          </li>
        </ul>
      </div>
      <div className="navbar-end">
        <button
          onClick={handlLogin}
          className="lg:block hidden btn btn-md bg-transparent text-primary border border-primary rounded-xl px-9 hover:bg-transparent hover:text-success hover:border-success"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Navbar;
