import React from "react";
import Cash from "../images/cash.png";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  return (
    <div className="relative min-h-screen sm:mt-32 mt-24">
      <div className="sm:px-40 px-5 pt-9 sm:pb-5 pb-10">
        <h3 className="font-normal sm:text-2xl text-lg text-primary text-center">
          Tipkit takes a thoughtful and personalized approach to understanding
          the unique needs of each hotel when setting up accounts, ensuring that
          we tailor our services to support your specific goals and
          requirements.
        </h3>
      </div>
      <div className="sm:p-16 flex flex-col sm:flex-row sm:justify-around mb-16 gap-8 sm:mx-0 mx-4">
        {/* --------------------------------------------- SMART CASH -------------------------------------- */}
        <div className="w-full sm:w-[420px] h-[400px] sm:h-[420px] relative p-9 border-2 border-primary rounded-xl">
          <div className="flex items-center justify-center">
            <h3 className="sm:text-4xl text-2xl font-normal text-primary text-start">
              SMART CASH
            </h3>
            <img className="w-16" src={Cash} alt="Cash Bunny" />
          </div>
          {/* Features List */}
          <div className="mt-9">
            <div className="text-md font-normal text-primary my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                One Time Set Up Fee
              </div>
            </div>
            <div className="text-md font-normal text-primary my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                Standard white label
              </div>
            </div>
            <div className="text-md font-normal text-primary my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                Digital tipping chatbot
              </div>
            </div>
          </div>
          <div className="flex justify-center absolute bottom-0 left-0 right-0 mb-5 sm:mt-0 mt-10 ">
            <button
              onClick={navigateToContact}
              className="btn btn-lg bg-transparent text-primary border border-primary rounded-xl px-4 hover:bg-transparent hover:text-success hover:border-success"
            >
              Unlock Now
            </button>
          </div>
        </div>
        {/* --------------------------------------------- ADVANCE CASH -------------------------------------- */}
        <div className="w-full h-[400px] sm:w-[440px] sm:h-[420px] relative p-9 bg-gradient-to-r from-primary to-neutral border border-primary rounded-xl">
          <div className="flex items-center justify-center">
            <h3 className="sm:text-4xl text-2xl font-normal text-white text-start">
              ADVANCED CASH
            </h3>
            <img className="w-16" src={Cash} alt="Cash Bunny" />
          </div>
          {/* Features List */}
          <div className="mt-9">
            <div className="text-md font-normal text-white my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                One Time Set Up Fee
              </div>
            </div>
            <div className="text-md font-normal text-white my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                Chatbot customizations
              </div>
            </div>
            <div className="text-md font-normal text-white my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                Payroll integrations
              </div>
            </div>
            <div className="text-md font-normal text-white my-2">
              <div className="flex items-center ">
                <svg
                  className="w-7 fill-success me-1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5071 10.5245C15.8 10.2316 15.8 9.75674 15.5071 9.46384C15.2142 9.17095 14.7393 9.17095 14.4464 9.46384L10.9649 12.9454L9.55359 11.5341C9.2607 11.2412 8.78582 11.2412 8.49293 11.5341C8.20004 11.827 8.20004 12.3019 8.49294 12.5947L10.4346 14.5364C10.7275 14.8293 11.2023 14.8292 11.4952 14.5364L15.5071 10.5245Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z"
                  />
                </svg>
                Monthly maintenance SaaS
              </div>
            </div>
          </div>
          <div className="flex justify-center absolute bottom-0 left-0 right-0 mb-5">
            <button
              onClick={navigateToContact}
              className="btn btn-lg bg-transparent text-white border border-white rounded-xl px-4 hover:bg-transparent hover:text-success hover:border-success"
            >
              Unlock Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
