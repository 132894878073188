import { Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Contact from "./screens/Contact";
import Terms from "./screens/Terms";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Footer from "./components/Footer";
import Pricing from "./screens/Pricing";

function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
